<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-fixed"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent
    }"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row  md-collapse-lateral">
      <div class="md-toolbar-section-start">
          <span class="md-title"><a href="#/"><img src="/images/POCh-logo.png"/></a></span>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>
            <md-list class="poch-menu">
              <md-list-item href="#/">
                <p>Home</p>
              </md-list-item>
              <li class="md-list-item">
                <a href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown">
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown">
                        <p>About</p>
                      </md-button>
                      <ul class="dropdown-menu">
                        <li v-for="li in linksSections"
                          :key="li.name">
                          <a :href="'#/#' + li.href"
                            @click=" () => {
                                NavbarStore.showNavbar = false;
                                toggledClass = false;
                              } ">
                            {{ li.name.charAt(0).toUpperCase() + li.name.slice(1) }}
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>
              
              <li class="md-list-item">
                <a href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown">
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown">
                        <p>Health Modules</p>
                      </md-button>
                      <ul class="dropdown-menu">
                        <li v-for="li in moduleSections"
                          :key="li.name">
                          <a :href="'#/modules#' + li.href"
                            @click=" () => {
                                NavbarStore.showNavbar = false;
                                toggledClass = false;
                              } ">
                            {{ li.name.charAt(0).toUpperCase() + li.name.slice(1) }}
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>
              <md-list-item href="/#/contact">
                <p>CONTACT</p>
              </md-list-item>
            <md-button class="md-primary" href="https://portal.pochealth.online/" target="_blank"> Portal </md-button>
            </md-list>
            
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";
export default {
  components: {
    MobileMenu
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
          "dark"
        ].includes(value);
      }
    },
    colorOnScroll: {
      type: Number,
      default: 0
    },
    navbarTransparent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      extraNavClasses: "",
      toggledClass: false,
      linksSections: [
        { name: "About", href: "about"},
        { name: "Advantages", href: "advantages"},
        { name: "How This Works", href: "how" },
        { name: "FAQ", href: "faq" },
        { name: "Modules", href: "modules" }
      ],
      moduleSections: [
        { name: "CPAMS", href: "cpams"},
        { name: "Gout Buster", href: "gout"},
        { name: "Future Modules", href: "future" }
      ],
    };
  },
  computed: {
    showDownload() {
      const excludedRoutes = ["index"];
      return excludedRoutes.every(r => r !== this.$route.name);
    }
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    }
  }
};
</script>
