<template>
  <div class="wrapper">
    <!-- Intro  -->
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">Contact Us</h1>
          </div>
        </div>
      </div>
    </parallax>
    <div id="main-panel" class="main main-raised">
      <div class="section section-sections">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto text-center">
          <h3 class="description">Get in touch to learn more about our programmes and how we can work together. We are always interested in hearing from potential collaborators about developing and trialling new point of care health modules.<a href="mailto:sales@pochealth.online"> Email: sales@pochealth.online </a>
            </h3>
        
              <p>For existing clients facing technical issues, please contact us at <a href="mailto:support@pochealth.online">support@pochealth.online</a>  
   or free phone the helpdesk on<a :href="'tel:+64800033066'"> 0800 033 066</a> or contact us via our live chat feature below between the hours of 9am and 5pm, Monday to Friday (excluding public holidays).</p>
   
           <p>Please note that we are IT Providers and as such are unable to offer medical advice. Please contact your pharmacist or GP for help. </p>
          
            </div>
            <div class="md-layout-item text-center">
              <md-button class="md-primary" href="mailto:support@pochealth.online">Email: support@pochealth.online</md-button>
            </div>
            <div class="md-layout-item text-center">
              <md-button class="md-primary" href="tel:+64800033066">Phone:  0800 033 066</md-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";

export default {
  name: "Contact",
  components: {
  },
  mixins: [Mixins.VerticalNav, Mixins.HeaderImage],
  data() {
    return {
      image: require("@/assets/img/456813301.jpg"),
    };
  }
};
</script>

<style scoped>

</style>