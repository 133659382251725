<template>

  <footer
      class="footer"
      :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size
    }"
      :data-background-color="backgroundColor">
      
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="#/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="https://firecrest.co.nz/" target="_blank">Firecrest Systems</a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
             <img src="/images/POCh-logo-FC.png" alt="Point of Care Health Online delivered by Firecrest" width="250px">
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
    size: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>

<style>

</style>
