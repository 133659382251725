<!-- Intro and tag div start   -->
<template>
  <div class="main-raised-page-wrapper  " >
   <div class="backgroundImgIntro"></div>
    <div id="main-page"
         class="main-white main-raised-lg">
      <div class="section text-info">
        <div class="container">
  
          <div class="md-layout">
            <div class="md-layout-item">
            
            </div>
            <div class="md-layout row" >
              <span class="col-sm"><img src="/images/poch-plus.png" alt="POCH Logo"  class="pochPlusImg"/></span>
            </div>
            
            <div class="row">
              <!-- Do this (tasha note) -->
              <span><h2 class="col-md-8 ml-auto mr-auto text-center "><strong>Point of Care Health Online</strong></h2> </span>
            </div>
          </div>
      
  
          <hr/>
  
   
          <div class="">
              <p class="introText">Online software for healthcare professionals to manage patient health by providing easier access to testing and faster results.  </p>
            </div>
       
  
      </div>
    </div>
    </div>
    <!-- Intro and tag end -->
  <!-- Create a div with a shadow css -->
  
  
  
    <br>
  
  <!-- About us Start -->
  <div class="md-md" >
    <div class="backgroundImgIntro"></div>
  
    <div id="main-page"
         class="main-white main-raised-lg">
         
      <div class="section text-info">
        <div class="container">
  
          <div class="md-layout">
         
            <div class="md-layout row ">
              <span class="col-sm"><img src="/images/poch-plus.png" alt="POCH Logo"  class="pochPlusImg"/></span>
              <span><h2 class="col-md-8 ml-auto mr-auto text-center"><strong>About us</strong></h2> </span>
            </div>
  
            <div class="row">
            </div>
          </div>
      
  
          <hr/>
  
          <div class="">
              <p class="md-body-1">
                Since 2008, Firecrest Systems have been a solution provider in IT services for businesses and organisations around New Zealand, by building software solutions tailored to their needs.  <br> <br> 
                Firecrest Systems also have a growing Point of Care Health department. One of our main modules, used in pharmacies across New Zealand, is the CPAMS programme. This programme is based on using INR Online, an award-winning web-based platform, to manage the treatment of patients on warfarin in pharmacies.  <br> <br> 
                Our latest version of the platform is called POC Health Online which is a fully cloud based system. This has been the introduction of our expanded service in other areas which include our second module, called “Gout Buster”. This is currently being trialled in 13 pharmacies across Counties Manukau DHB to manage the treatment of patients suffering from Gout by regular monitoring of their serum urate level and regulating their allopurinol doses. <br> <br>             
             </p>
            </div>
       
  
        </div>
      </div>
    </div>
  </div>
  
  <!-- About us end -->
  
  
  
  <br>
  <!-- Advantages of PocH Start -->
  <div class="backgroundImgAdvantages"></div>
  
  <div class="md-md" >
    <div id="main-page"
         class="main-white-advantages main-raised-lg">
        
      <div class="section text-info">
        
        <div class="container">
  
          <div class="md-layout">
            <div class="md-layout-item">
            
            </div>
            <div class="md-layout row ">
              <span class="row"><img src="/images/poch-plus.png" alt="POCH Logo"  class="pochPlusImg"/></span>
              <span><h2 class="advantagesText"><strong>Advantages of Point of Care Health Management</strong></h2> </span>
            </div>
  
            <div class="row">
            </div>
          </div>
      
  
          <hr/>
  
          <div class="">
              <ul class="advantageList md-body-1">
                <li> Easier for patients to get tested regularly and less waiting times.  </li>
                <li>The GP gets sent a copy of the result. </li>
                <li>Result can be shared with 3rd parties like TestSafe and TAS allowing results to be included in clinical portals.  </li>
                <li>Takes the pressure of the health system by including pharmacists in the healthcare of patients  </li>
                <li>Cheaper on the health system by keeping patients out of the hospitals.  </li>
  
              </ul>
            </div>
       
  
        </div>
      </div>
    </div>
  </div>
  
  <!-- Advantages of PocH end -->
  <br>
  
  
  <!-- CPAMS what it is Start -->
  <div class="md-md" id="cpams" >
    <div id="main-page"
         class="main-white main-raised-lg">
      <div class="section text-info">
        <div class="container">
  
          <div class="md-layout">
            <div class="md-layout-item">
            
            </div>
            <div class="md-layout row ">
              <span class="col-sm"><img src="/images/poch-plus.png" alt="POCH Logo"  class="pochPlusImg"/></span>
              <span><h2 class="col-sm "><strong>CPAMS</strong></h2> </span>
            </div>
  
            <div class="row">
         
            </div>
          </div>
      
  
          <hr/>
  
          <div class="">
              <p class="md-body-1">
                The Community Pharmacy Anticoagulation Management Service (CPAMS) is a point of care programme that allows accredited pharmacists to monitor a patient’s INR level and adjust their warfarin dosage using the INR Online platform.   
  
                <br> <br>             
             </p>
            </div>
  
       
            <div class="md-layout row ">
              <span class="col-sm"><img src="/images/poch-plus.png" alt="POCH Logo"  class="pochPlusImg"/></span>
              <span><h2 class="col-sm cpamsTitle"><strong>CPAMS Programme</strong></h2> </span>
            </div>
            <hr/>
  
            <div class="">
              <p class="md-body-1">
                A GP signs a standing order for a patient who has been prescribed warfarin. They are then referred to a pharmacy for regular monitoring of their INR levels and managing their warfarin dosage.     <br> <br> 
                This is accomplished by testing a patient’s INR level by using just one drop of blood via a fingerprick test via Roche’s CoaguChek XS Plus or Pro device which performs a fingerprick test and the data is input into INR Online via plugging it in to the platform. The data can also be input into the system manually. The pharmacist then asks the patient questions about their bleeding history and adherence, which are listed on the INR platform. Once these are answered, the algorithm then calculates the dose of warfarin the patient should be on till their next check-up. A treatment calendar showing the dosage to be taken on each day of the week is then available to be printed out and given to the patient.    <br> <br> 
                Results are sent to the medical centre that holds the standing order for the patient via HealthLink HL7 messaging. A result can be put into review to be checked by the GP, if required. In addition to this being sent via HealthLink HL7 messaging, if the email of the medical centre is registered in the system, the review notification is also sent via email. The results are also sent via HealthLink HL7 messaging to TestSafe for their respective patients, as well as made available to TAS via File Transfer Protocol. <br> <br>             
             </p>
            </div>
  
  
  
        </div>
      </div>
    </div>
  </div>
  
  <!-- CPAMS end -->
  
  <br>
  
  <!-- Gout Buster what it is Start -->
  <div class="md-md" id="cpams" >
    <div id="main-page"
         class="main-white main-raised-lg">
      <div class="section text-info">
        <div class="container">
  
          <div class="md-layout">
            <div class="md-layout-item">
            
            </div>
            <div class="md-layout row ">
              <span class="col-sm"><img src="/images/poch-plus.png" alt="POCH Logo"  class="pochPlusImg"/></span>
              <span><h2 class="col-sm"><strong>Gout Buster  </strong></h2> </span>
            </div>
  
            <div class="row">
         
            </div>
          </div>
      
  
          <hr/>
  
          <div class="">
              <p class="md-body-1">
                The Gout Buster programme is a point of care programme that allows trained pharmacists to monitor a patient’s serum urate level and adjust their allopurinol dosage using the Gout Buster module on our POCH Health Online platform
                <br> <br>             
             </p>
            </div>
  
       
            <div class="md-layout row ">
              <span class="col-sm"><img src="/images/poch-plus.png" alt="POCH Logo"  class="pochPlusImg"/></span>
              <span><h2 class="col-sm"><strong>Gout Buster Programme</strong></h2> </span>
            </div>
            <hr/>
  
            <div class="">
              <p class="md-body-1">
                A GP can sign a standing order for a patient who has been prescribed allopurinol. They are then referred to a pharmacy for 4-weekly check-ups of their serum urate levels and managing their allopurinol dosage.    <br> <br> 
                A patient must have the lab results from a recent eGFR test at the first visit to a pharmacy. This is taken into account when calculating the starting allopurinol dosage, for people who have not been on allopurinol before. The patient’s serum urate level is tested by using just one drop of blood via a fingerprick test using a Benecheck serum urate meter. The reading is input into the system manually. The pharmacist then asks the patient a series of questions related to adherence and previous gout history, which are listed on the system and decides on the dosage for the patient. A printed summary of the prescribed dose, the date of the next test, the name of the pharmacy where the test was done, and the treatment calendar showing the dose to be taken on each day of the week is then handed to the patient. The current dose of allopurinol included in the summary is also available in the patient’s language of choice out of Te Reo, Cook Island Māori, Samoan, Tongan and English.     <br> <br> 
                The starting dosage is decided according to the standing order and taking into account the patients eGFR levels and whether they are already on allopurinol. The standing order also dictates the maximum level the allopurinol dosage can be increased to, as well as which patients are eligible to be managed by pharmacies based on their eGFR levels.   <br> <br>
                Results are sent to the medical centre that holds the standing order for the patient via HealthLink HL7 messaging. A result can be put into review to be checked by the GP, if required. In addition to this being sent via HealthLink HL7 messaging, if the email of the medical centre is registered in the system, the review notification is also sent via email. The results are also sent via HealthLink HL7 messaging to TestSafe for their respective patients, as well as made available to TAS via File Transfer Protocol.    <br> <br>
                The novel addition to the Gout Buster module is the optional text messaging reminder service, as well as the motivational texts that are sent out for the first 5 months from a patient’s enrolment. These text reminders are available in the patient’s chosen language out of Te Reo, Cook Island Māori, Samoan, Tongan and English. This is to minimize the number of patients dropping out of the programme, as well as, encouraging adherence. For patients or pharmacies who do not want to opt into the text message reminder programme, we also have the option to send these via email.   <br> <br>
                We have also implemented Multi Factor Authentication (MFA) on the POCH Online platform to tighten security and protect medical information.   <br> 
              </p>
            </div>
  
  
  
        </div>
      </div>
    </div>
  </div>
  
  <!-- Gout Buster end -->
  <br>
  <!-- How it works Start -->
  <div class="md-md" >
    <div id="main-page"
         class="main-white main-raised-lg">
      <div class="section text-info">
        <div class="container">
  
          <div class="md-layout">
            <div class="md-layout-item">
            
            </div>
            <div class="md-layout row ">
              <span class="row"><img src="/images/poch-plus.png" alt="POCH Logo"  class="pochPlusImg"/></span>
              <span><h2 class="row-sm"><strong>How it works (center this)</strong></h2> </span>
            </div>
  
            <div class="row">
            </div>
          </div>
      
  
          <hr/>
  
          <div class="md-body-1">
            <h2 class="md-body-1">The overall platform provides a tool kit of services that can be used in a variety of ways. These tools include but not limited to:  </h2>
              <ul class="advantageList md-body-1">
               <li>The ability to manage patients,  </li>
               <li>The ability to report on outcomes of patients,  </li>
               <li>The ability to record notes,  </li>
               <li>The ability to record results,  </li>
               <li>The ability to schedule test and appointments for patients,  </li>
               <li>The ability to send reminder and other communications to patients. Via email and text messages in their native language.</li>
               <li>The facilitation of passing information to GPs and DHBs.  </li>
               <li>The ability to integrate dosing algorithms, like with our warfarin management module INR Online.  </li>
              </ul> <br>
  
              <h2 class="md-body-1">The use of these individual tools can be combined to meet the requirements of most workflows.   </h2>
            </div>
            <img src="/images/track-treatment.png" alt="track treatment graph" class="trackTreatment">
  
       
  
        </div>
      </div>
    </div>
  </div>
  
  <!-- How it works end -->
  <br>
  <!-- FAQ  Start -->
  <div class="md-md" id="faq" >
    <div id="main-page"
         class="main-white main-raised-lg">
      <div class="section text-info">
        <div class="container">
  
          <div class="md-layout">
            <div class="md-layout-item">
            
            </div>
            <div class="">
              <span class="col-sm"><img src="/images/poch-plus.png" alt="POCH Logo"  class="pochPlusImg"/></span>
              <span><h2 class="col-sm"><strong>FAQ (Frequently Asked Questions)</strong></h2> </span>
            </div>
  
            <div class="row">
         
            </div>
          </div>
      
  
          <hr/>
  
          <div class="">
              <p class="md-body-1">
                <span><h2 class="col-sm"><strong>Can I include my POC Programme? </strong></h2> </span>
             </p>
             <p class="md-body-1">Our platform is set up to be able to meet most workflow requirements. If you are interested in developing or trialling a new Point of Care Health module, please  <a href="#contact"> <strong> get in touch </strong></a> </p>
            </div>
            <hr/>
            <br>
       
            <div class="md-layout row ">
              <span><h2 class="col-sm"><strong> Can My Pharmacy or medical centre use this? </strong></h2> </span>
            </div>
            <h3> <strong> Medical centres: </strong> </h3>
            <p class="md-body-1">
              We have several medical centres onboard who offer INR testing using our platform. If you are a medical centre interested in offering INR or Gout testing in your clinic, we can help you with your questions about how to set this up in your clinic.  
            </p>
            <h3> <strong> Pharmacies: </strong> </h3>
            <p class="md-body-1">
              If you are a pharmacy interested in offering INR or Gout testing and getting funded for providing this service, you will need to contact your Health NZ representative for a contract prior to following the steps as below.  
            </p>
            <h3> <strong> CPAMS: </strong> </h3>
            <p class="md-body-1"> Please contact the Pharmaceutical Society of New Zealand to arrange CPAMS certification training for your pharmacists who require a login account for the system.  
             Once this has been completed, please get in touch with us to set you up for the CPAMS programme on POC Health Online. </p>
  
             <h3> <strong> Gout Buster: </strong> </h3>
             <p class="md-body-1"> Please get in touch with us to organize training and set you up for the Gout Programme on POC Health Online.  </p>
  
            <hr/>
  
            <div class="">
              <p class="md-body-1">
                 <br> <br>             
             </p>
            </div>
  
  
  
        </div>
      </div>
    </div>
  </div>
  
  <!-- FAQ end -->
  <br>
  <!-- Contact  Start -->
  <div class="md-md" id="contact" >
    <div id="main-page"
         class="main-white main-raised-lg">
      <div class="section text-info">
        <div class="container">
  
          <div class="md-layout">
            <div class="md-layout-item">
            
            </div>
            <div class="md-layout row ">
              <span class="row"><img src="/images/poch-plus.png" alt="POCH Logo"  class="pochPlusImg"/></span>
              <span><h2 class="row-sm"><strong>Contact Us</strong></h2> </span>
            </div>
  
            <div class="row">
            </div>
          </div>
      
  
          <hr/>
  <!-- Mail to + telephone link -->
          <div class="md-body-1">
            <h2 class="md-body-1">Get in touch to learn more about our programmes and how we can work together. We are always interested in hearing from potential collaborators about developing and trialling new point of care modules.   </h2>
              <br>
            <p class="md-body-1">Email us at  <a href="mailto:support@pochealth.online">support@pochealth.online</a>   and we will get back to you shortly.  </p>
        
              <h2 class="md-body-1">For existing clients facing technical issues, please contact us at <a href="mailto:support@pochealth.online">support@pochealth.online</a>  
   or free phone the helpdesk on<a href= “tel:+0508927327”> 0508 927327</a> or contact us via our live chat feature between the hours of 9am and 5pm, Monday to Friday (excluding public holidays).   </h2> <br>
           <h2 class="md-body-1">Please note that we are an IT company and as such are unable to offer medical advice. Please contact your pharmacist or GP for help. </h2>
            </div>
       
  
        </div>
      </div>
    </div>
  </div>
  
  <!-- contact us end -->
  
  
  
  
  
  
  </div>
  
  
  
  </template>

<script>
export default {
  name: "Page"
}
</script>

<style scoped>.main-raised-page-wrapper {
  padding-top: 160px;
}

.md-toolbar .md-title {

  height: 100px !important;

}

.pochPlusImg {
 width: 5rem;
 display: flex;
   margin-right: 200px;

}


.introText{
  text-align: center;
  font-size: 2rem;
}

.md-body-1 {
  font-size: 20px !important;
  /* color: brown !important; */
}

.advantageList {
  text-align: center;
  justify-content: center;
  align-items: center ;
}



.test {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(190, 241, 255);
  padding: 2rem;
  /* box-shadow: 0 0 10px rgba(0,0,0,0.5); */
  /* display in centre */
  margin: 0 auto;
  /* width of the element */
}

/* Create container in the middle of page with white background */
.main-white {
  position: relative;
  overflow: hidden;

  background-color: white;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(39,170,225,1);
  margin: 0 auto;
  width: 50%;
  border-radius: 10px;
  margin-top: 15px;
}

/* Add background image behind .main-white */

.backgroundImgIntro {
background-repeat: no-repeat;
position: absolute;
width: 100%;
height: 92%;
background-color: #c1e8f3;
background-image: linear-gradient(to right, #82c0f3, #69caf3, #c1e8f3)

}

.backgroundImgAbout {
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 15%;
  background-color: #ffffff;
  background-image: linear-gradient(to right, #5fa2d8, #4ab5e4, #c1e8f3)
  
  }


/* .backgroundImgAdvantages {
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: %;
  background-color: #ffffff;
  background-image: linear-gradient(to right, #5fa2d8, #4ab5e4, #c1e8f3)
} */

.trackTreatment {
  float:right;
  background-color:#aaa;
  overflow:auto;
  width: 50%;
  height: 50%;
}
.trackTreatment img {
  float: right;

}

/* centre pochPlusImg logo */
.pochPlusImg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
}

.pochPlusImgIntro {
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggledClass:hover {
  background-color: rgb(0, 19, 19) !important;
  
}


.combinedLogo {
  width: 30%;
  height: 30%;
}


.main-white-advantages {
  position: relative;
  overflow: hidden;
  background-color: white;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(39,170,225,1);
  margin: 0 auto;
  width: 50%;
  border-radius: 10px;
  margin-top: 15px;
  display: flex;
}


.advantagesText {
text-align: center;
margin-right: 150px;


}

.cpamsTitle {
  text-align: center;
  margin-right: 150px;
}

</style>