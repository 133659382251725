import Vue from "vue";
import Router from "vue-router";

//  Main Pages
import Index from "@/views/Index";
import Contact from "@/views/Contact";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import Page from "@/views/Page";
import Modules from "@/views/Modules";
import Documents from "@/views/Documents";

import MainNavbar from "@/layout/MainNavbar";
import MainFooter from "@/layout/MainFooter";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      alias: ["/index", "/home"],
      name: "index",
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false }
      }
    },
    {
      path: "/modules",
      name: "modules",
      components: { default: Modules, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false }
      }
    },
    {
      path: "/page",
      name: "page",
      components: { default: Page, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false }
      }
    },
    {
      path: "/documents",
      name: "documents",
      components: { default: Documents, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false }
      }
    },
    {
      path: "/contact",
      name: "contact",
      components: { default: Contact, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false }
      }
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      components: { default: PrivacyPolicy, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
