<template>
<div class="main-raised-page-wrapper">
  <div id="main-panel"
       class="main main-raised">
    <div class="section">
      <div class="container">
        
        <h1 class="title">
              Documents
            </h1>
                <h5><a href="/document/Gout-Buster-Troubleshooting.pdf" target="_blank">
                      <i class="material-icons">article</i> Gout Buster Troubleshooting Guide</a></h5>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped>

</style>