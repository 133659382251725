<template>
  <div class="wrapper">
    <!-- Intro  -->
    <parallax
      class="section page-header header-filter" id="home"
      parallax-active="true"
      :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-70 md-small-size-100">
            <h1 class="title">Point of Care Health Online</h1>
            <h4>Online software for healthcare professionals to manage patient health by providing easier access to testing and faster results.</h4>
            <img src="/images/ALT-POCh-logo-FC.png" alt="Point of Care Health Online"  class="pochPlusImg"/>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
          </div>
        </div>
      </div>
    </parallax>
    <div id="main-panel" class="main main-raised">
      <!-- What is POC Section -->
      <div class="section section-sections">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto text-center">
              <h2 class="title text-center">
                What is POC?
              </h2>
              <h3 class="description">

Point of care (POC) refers to medical testing, diagnostic, or treatment procedures that can be performed at or near the location where a patient is receiving care, allowing for faster diagnosis and treatment decisions without sending samples to a laboratory or waiting for results from a remote facility.
              </h3>
              <h5>
Pharmacies are an increasingly popular location for POC testing. Many pharmacies now offer POC tests for conditions such as flu, strep throat, and COVID-19, which can provide patients with quick results without having to go to a separate medical facility. In some cases, qualified pharmacists can also perform POC tests for long term treatments, such as monitoring INR and serum urate levels, allowing for better management of patients on warfarin and allopurinol respectively. These tests are able to be done with a single drop of blood from a fingerprick, rather than repetitive venous blood tests as done in the laboratory.             
              </h5>
              <h5>
POC testing in pharmacies provide a way to increase access to healthcare services, especially in areas with limited medical resources or where patients have difficulty accessing traditional healthcare facilities. This also allows for mobile testing services to be offered when a patient is physically unable to visit a facility for regular testing.         
             </h5>
            </div>
          </div>
        </div>
<div id="advantages"></div>
      </div>
<div id="about"></div>
      <!-- About us Section -->
      <div class="section  section-grey">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto text-center">
              <h2 class="title text-center">
                About
              </h2>
              <h3 class="description">
                Since 2008, Firecrest Systems has been a solution provider in IT services for businesses and organisations around New Zealand, by building software solutions tailored to their needs.
              </h3>
              <h5>
                Firecrest Systems also has a growing point of care health department. One of our main modules, used in pharmacies across New Zealand, is the CPAMS programme. This programme is based on using INR Online, an award-winning web-based platform, to manage the treatment of patients on warfarin in pharmacies.            
              </h5>
              <h5>
                Our latest version of the platform is called POC (Point of Care) Health Online which is a fully cloud based system. This has been the introduction of our expanded service in other areas which include our second module, called “Gout Buster”. This is currently being trialled in 13 pharmacies across Counties Manukau to manage the treatment of patients suffering from gout by regular monitoring of their serum urate level and regulating their allopurinol doses.            
             </h5>
            </div>
          </div>
        </div>
<div id="advantages"></div>
      </div>
      
      <!-- Advantages Section -->
      <div class="section section-features-5 section-image"
      :style="features5">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h2 class="title text-center">
                Advantages of Point of Care Health Management
              </h2>
              <h5>
              <ul>
                <li>Easier for patients to get tested regularly and reduced waiting time for tests</li>
                <li>The GP gets sent a copy of the results</li>
                <li>Result can be shared with third parties like TestSafe and TAS allowing results to be included in clinical portals</li>
                <li>Takes the pressure off the health system by including pharmacists in the healthcare of patients</li>
                <li>More cost effective than laboratory testing, keeping patients out of the hospitals</li>
              </ul>
              </h5>
            </div>
          </div>
        </div>
<div id="how"></div>
      </div>
      
      <!-- How it works Section -->
      <div class="section section-dark">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h2 class="title text-center">
                How it works
              </h2>
              <h3 class="title">
                The overall platform provides a tool kit of services that can be used in a variety of ways. These include:
              </h3>
              <h5 class="description">
              <ul>
               <li>The ability to manage patients</li>
               <li>Report on outcomes of patients</li>
               <li>Record notes</li>
               <li>Record results</li>
               <li>Schedule test and appointments for patients</li>
               <li>Send reminder and other communications to patients, via email and text messages in their native language</li>
               <li>The facilitation of passing information to GPs and Te Whatu Ora (Health NZ)</li>
               <li>The ability to integrate dosing algorithms, like with our warfarin management module INR Online</li>
              </ul></h5>
              <h3 class="title">
                The use of these individual tools can be combined to meet the requirements of a variety of workflows.
              </h3>
            </div>
          </div>
        </div>
<div id="faq"></div>
      </div>
      
      <!-- FAQ Section -->
      <div class="section  section-grey">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h2 class="title text-center">
                FAQ (Frequently Asked Questions)
              </h2>
              <h3 class="info-title">Can I include my point of care programme?
              </h3>
              <p>Our platform is set up to be able to meet a variety of workflows. If you are interested in developing or trialling a new point of care health module, please <a href="/#/contact"> get in touch </a>
              </p>
              <h3 class="info-title">Can my medical centre use this?
              </h3>
              <p>We have several medical centres onboard who offer INR testing using our platform. If you are a medical centre interested in offering INR or Gout testing in your clinic, we can help you with your questions about how to set this up in your clinic.
              </p>
              <h3 class="info-title">Can my pharmacy use this?
              </h3>
              <p>If you are a pharmacy interested in offering INR or Gout testing and getting funded for providing this service, you will need to contact your Te Whatu Ora (Health NZ) representative for a contract prior to getting set up. 
              </p>
              <h3 class="info-title">How can our pharmacists get set up with CPAMS?
              </h3>
              <p>Please contact the Pharmaceutical Society of New Zealand to arrange CPAMS certification training for your pharmacists who require a login account for the system. Once this has been completed, please get in touch with us to set you up for the CPAMS programme on POC Health Online. 
              </p>
              <h3 class="info-title">How can our pharmacists get set up with Gout Buster?
              </h3>
              <p>Please <a href="/#/contact"> get in touch </a> with us to organise training and set you up for the Gout Programme on POC Health Online. 
              </p>
            </div>
          </div>
        </div>
<div id="modules"></div>
      </div>
      
      <!-- Modules Section -->
      <div class="section section-sections">
        <div class="container">
          <div class="md-layout modules">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h2 class="title text-center">
                Our Modules
              </h2>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <pricing-card card-bg="info">
                <template slot="cardContent">
                  <h2 class="title text-center">
                    CPAMS
                  </h2>
                  <h4 class="text-info">
                    The Community Pharmacy Anticoagulation Management Service (CPAMS) is a point of care programme that allows accredited pharmacists to monitor a patient’s INR level and adjust their warfarin dosage.
                  </h4>
                  <md-button class="md-white" href="#/modules#cpams">More about our CPAMS module</md-button>
                </template>
              </pricing-card>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <pricing-card card-bg="info">
                <template slot="cardContent">
                  <h2 class="title text-center">
                    Gout Buster
                  </h2>
                  <h4 class="text-info">
                    The Gout Buster programme is a point of care programme that allows trained pharmacists to monitor a patient’s serum urate level and adjust their allopurinol dosage.
                  </h4>
                  <md-button class="md-white" href="#/modules#gout">More about our Gout module</md-button>
                </template>
              </pricing-card>
            </div>
            <div class="md-layout-item md-size-70 md-small-size-100">
              <br>
              <h4 class="title">We are always interested in hearing from potential collaborators about developing and trialling new point of care health modules.<a href="mailto:sales@pochealth.online"> Email: sales@pochealth.online </a><br></h4>
              <md-button class="md-primary" href="#/contact">Get in touch</md-button>
            </div>
          </div>
        </div>
      </div>

    </div>
    
    <nav id="cd-vertical-nav" class="vertical-nav-active" >
      <ul>
        <li>
          <a href="javascript:void(0)" data-number="1" @click="scrollToElement('about')">
            <span class="cd-dot" />
            <span class="cd-label">About</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" data-number="2" @click="scrollToElement('advantages')">
            <span class="cd-dot" />
            <span class="cd-label">Advantages</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" data-number="3" @click="scrollToElement('how')">
            <span class="cd-dot" />
            <span class="cd-label">How It Works</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" data-number="4" @click="scrollToElement('faq')">
            <span class="cd-dot" />
            <span class="cd-label">Frequently Asked Questions</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" data-number="5" @click="scrollToElement('modules')">
            <span class="cd-dot" />
            <span class="cd-label">Our Modules</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";
import { PricingCard } from "@/components/material-kit";

export default {
  name: "Index",
  components: {
    PricingCard
  },
  mixins: [Mixins.VerticalNav, Mixins.HeaderImage],
  data() {
    return {
      image: require("@/assets/img/456813301.jpg"),
      bkimage: require("@/assets/img/430515127.jpg"),
      letsTalkModal: false,
    };
  },
  computed: {
    features5() {
      return {
        backgroundImage: `url(${this.bkimage})`
      };
    }
  }
};
</script>
<style lang="scss">
.vertical-nav-active {
  display: block;
}
#about{
  padding-top:80px;
}
@media all and (max-width: 1358px) {
  .vertical-nav-active {
    display: none;
  }
}
.modules .md-card
{height:90%;}
</style>
