<template>
  <div class="wrapper">
    <!-- Intro  -->
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">Healthcare modules</h1>
            <h4>Online software for healthcare professionals</h4>
              <h3> 
              <a class="title" href="javascript:void(0)"  @click="scrollToElement('cpams')">
                CPAMS Module
              </a> | 
              <a class="title" href="javascript:void(0)"  @click="scrollToElement('gout')">
                Gout Buster Module
              </a> |  
              <a class="title" href="javascript:void(0)"  @click="scrollToElement('future')">
                Future Modules
              </a></h3>
          </div>
        </div>
      </div>
    </parallax>
    <div id="main-panel" class="main main-raised">
      <div class="section">
<div id="cpams"></div>
      </div>
      <!-- CPAMS Section -->
      <div class="section section-sections">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h2 class="title text-center">
                Community Pharmacy Anticoagulation Management Service (CPAMS)
              </h2>
              <h3 class="description">
                The Community Pharmacy Anticoagulation Management Service (CPAMS) is a point of care programme that allows accredited pharmacists to monitor a patient’s INR level and adjust their warfarin dosage using the INR Online platform.
              </h3>
            </div>
            <div class="md-layout-item md-size-66 md-small-size-100">
              <h3 class="title">
                CPAMS Programme
              </h3>
              <p>
                A GP signs a standing order for a patient who has been prescribed warfarin. They are then referred to a pharmacy for regular monitoring of their INR levels and managing their warfarin dosage.
              </p>
              <p>
                This is accomplished by testing a patient’s INR level by using just one drop of blood via a fingerprick test using Roche’s CoaguChek XS Plus or Pro device. The data is then input into INR Online via plugging it into the platform. The data can also be input into the system manually. The pharmacist then asks the patient questions about their bleeding history and adherence, which are listed on the INR platform. Once these are answered, the algorithm then calculates the dose of warfarin the patient should be on until their next check-up. A treatment calendar showing the dosage to be taken on each day of the week is then available to be printed out and given to the patient.
              </p>
              <p>
                Results are sent to the medical centre that holds the standing order for the patient via HealthLink HL7 messaging. A result can be put into review to be checked by the GP, if required. In addition to this being sent via HealthLink HL7 messaging, if the email of the medical centre is registered in the system, the review notification is also sent via email. The results are also sent via HealthLink HL7 messaging to TestSafe for their respective patients, as well as made available to TAS.
              </p>
            </div>
          </div>
        </div>
<div id="gout"></div>
      </div>
    
      <!-- Gout Buster Section -->
      <div class="section section-grey">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <h2 class="title text-center">
                Gout Buster
              </h2>
              <h3 class="description">
                The Gout Buster programme is a point of care programme that allows trained pharmacists to monitor a patient’s serum urate level and adjust their allopurinol dosage using the Gout Buster module on our POC Health Online platform
              </h3>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100 mx-auto imgcol">
                <p><img :src="screenshot1"
                alt="Raised Image"
                class="img-raised rounded"
                :class="{ 'responsive-image': responsive }"></p>
                <p><img :src="screenshot2"
                alt="Raised Image"
                class="img-raised rounded"
                :class="{ 'responsive-image': responsive }"></p>
                <p><img :src="screenshot3"
                alt="Raised Image"
                class="img-raised rounded"
                :class="{ 'responsive-image': responsive }"></p>
            </div>
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h3 class="title">
                Gout Buster Programme
              </h3>
              <p>
                A GP can sign a standing order for a patient who has been prescribed allopurinol. They are then referred to a pharmacy for 4-weekly check-ups of their serum urate level and managing their allopurinol dosage.
              </p>
              <p>
                When a patient first visits the pharmacy, the patient’s serum urate level is tested by using just one drop of blood via a fingerprick test using a Benecheck serum urate meter. The reading is input into the system manually. The pharmacist then asks the patient a series of questions related to adherence and previous gout history, which are listed on the system and decides on the starting dosage for the patient based on their standing order and whether they are already on allopurinol. The standing order also dictates the maximum level the allopurinol dosage can be increased to. A printed summary of the prescribed dose, the date of the next test, the name of the pharmacy where the test was done, and the treatment calendar showing the dose to be taken on each day of the week is then handed to the patient.
              </p>
              <p>
                Results are sent to the medical centre that holds the standing order for the patient via HealthLink HL7 messaging. The results are also sent via HealthLink HL7 messaging to TestSafe for their respective patients, as well as made available to TAS.
              </p>
              <p>
                The novel addition to the Gout Buster module is the optional text messaging reminder service, as well as the motivational texts that are sent out for the first 5 months from a patient's first check-up. These text reminders are available in the patient’s chosen language out of Te Reo, Cook Island Māori, Samoan, Tongan and English. This is to minimize the number of patients dropping out of the programme, as well as, encouraging adherence. For patients or pharmacies who do not want to opt into the text message reminder programme, we also have the option to send these via email.
              </p>
              <p>
                We have also implemented Multi Factor Authentication (MFA) on the POC Health Online platform to tighten security and protect medical information.
              </p>
              <p><a href="#/documents">
                      <i class="material-icons">article</i> See our useful documents</a></p>
            </div>
          </div>
        </div>
<div id="future"></div>
      </div>
      <div class="section section-sections">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto text-center">
              <h2 class="title">
                Future modules
              </h2>
              <h3 class="description">We are currently developing more modules to extend our healthcare range.
              </h3>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <br>
              <h4 class="title">We are always interested in hearing from potential collaborators about developing and trialling new point of care health modules.</h4>
              <md-button class="md-primary" href="#/contact">Get in touch</md-button>
            </div>
          </div>
        </div>
      </div>

    </div>
    
    <nav id="cd-vertical-nav" class="vertical-nav-active" >
      <ul>
        <li>
          <a href="javascript:void(0)" data-number="1" @click="scrollToElement('cpams')">
            <span class="cd-dot" />
            <span class="cd-label">CPAMS</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" data-number="2" @click="scrollToElement('gout')">
            <span class="cd-dot" />
            <span class="cd-label">GOUT BUSTER</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" data-number="2" @click="scrollToElement('future')">
            <span class="cd-dot" />
            <span class="cd-label">Future Modules</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";

export default {
  name: "Modules",
  components: {
  },
  mixins: [Mixins.VerticalNav, Mixins.HeaderImage],
  data() {
    return {
      image: require("@/assets/img/456813301.jpg"),
      screenshot1: require("@/assets/img/Screenshot-14DPat.png"),
      screenshot2: require("@/assets/img/Screenshot-ManagePat.png"),
      screenshot3: require("@/assets/img/Screenshot-Dash.png"),
      screenshot4: require("@/assets/img/Screenshot-INRPatientView.png"),
    };
  }
};
</script>
<style lang="css">
.imgcol{
  margin-top:60px;
}

@media all and (max-width: 1358px) {
  .vertical-nav-active {
    display: none;
  }
}
</style>