var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('md-toolbar',{staticClass:"md-fixed",class:( _obj = {}, _obj[("md-" + _vm.type)] = !_vm.navbarTransparent, _obj[_vm.extraNavClasses] = _vm.navbarTransparent, _obj['md-transparent'] =  _vm.navbarTransparent, _obj ),attrs:{"id":"toolbar","md-elevation":"0","color-on-scroll":_vm.colorOnScroll}},[_c('div',{staticClass:"md-toolbar-row  md-collapse-lateral"},[_c('div',{staticClass:"md-toolbar-section-start"},[_c('span',{staticClass:"md-title"},[_c('a',{attrs:{"href":"#/"}},[_c('img',{attrs:{"src":"/images/POCh-logo.png"}})])])]),_c('div',{staticClass:"md-toolbar-section-end"},[_c('md-button',{staticClass:"md-just-icon md-simple md-toolbar-toggle",class:{ toggled: _vm.toggledClass },on:{"click":function($event){return _vm.toggleNavbarMobile()}}},[_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"})]),_c('div',{staticClass:"md-collapse"},[_c('div',{staticClass:"md-collapse-wrapper"},[_c('mobile-menu',{attrs:{"nav-mobile-section":"false"}}),_c('md-list',{staticClass:"poch-menu"},[_c('md-list-item',{attrs:{"href":"#/"}},[_c('p',[_vm._v("Home")])]),_c('li',{staticClass:"md-list-item"},[_c('a',{staticClass:"md-list-item-router md-list-item-container md-button-clean dropdown",attrs:{"href":"javascript:void(0)"}},[_c('div',{staticClass:"md-list-item-content"},[_c('drop-down',{attrs:{"direction":"down"}},[_c('md-button',{staticClass:"md-button md-button-link md-white md-simple dropdown-toggle",attrs:{"slot":"title","data-toggle":"dropdown"},slot:"title"},[_c('p',[_vm._v("About")])]),_c('ul',{staticClass:"dropdown-menu"},_vm._l((_vm.linksSections),function(li){return _c('li',{key:li.name},[_c('a',{attrs:{"href":'#/#' + li.href},on:{"click":function () {
                              _vm.NavbarStore.showNavbar = false;
                              _vm.toggledClass = false;
                            }}},[_vm._v(" "+_vm._s(li.name.charAt(0).toUpperCase() + li.name.slice(1))+" ")])])}),0)],1)],1)])]),_c('li',{staticClass:"md-list-item"},[_c('a',{staticClass:"md-list-item-router md-list-item-container md-button-clean dropdown",attrs:{"href":"javascript:void(0)"}},[_c('div',{staticClass:"md-list-item-content"},[_c('drop-down',{attrs:{"direction":"down"}},[_c('md-button',{staticClass:"md-button md-button-link md-white md-simple dropdown-toggle",attrs:{"slot":"title","data-toggle":"dropdown"},slot:"title"},[_c('p',[_vm._v("Health Modules")])]),_c('ul',{staticClass:"dropdown-menu"},_vm._l((_vm.moduleSections),function(li){return _c('li',{key:li.name},[_c('a',{attrs:{"href":'#/modules#' + li.href},on:{"click":function () {
                              _vm.NavbarStore.showNavbar = false;
                              _vm.toggledClass = false;
                            }}},[_vm._v(" "+_vm._s(li.name.charAt(0).toUpperCase() + li.name.slice(1))+" ")])])}),0)],1)],1)])]),_c('md-list-item',{attrs:{"href":"/#/contact"}},[_c('p',[_vm._v("CONTACT")])]),_c('md-button',{staticClass:"md-primary",attrs:{"href":"https://portal.pochealth.online/","target":"_blank"}},[_vm._v(" Portal ")])],1)],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }